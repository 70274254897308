/*
	:: popup 1.0 ::

Принимает:
	openTag - тег кнопки открытия
	closeTag - тег кнопки закрытия
	rootClass (опционально) - класс для rootEl. По умолчанию is-popup-open
	rootTag (опционально) - в чем искать. По умолчанию body

Действие: при нажатии на кнопки openTag/closeTag убирает/добавляет rootClass у rootEl

Подключение:

// попап
const popup = require("./popup");

// попап
popup ({
	openTag: ".js-popup-open",
	closeTag: ".js-popup-close",
	rootClass: "is-popup-open",
	rootTag: "body"
});

*/

module.exports = function (option) {

	let rootTag = option.rootTag || "body"

	if (document.querySelector(rootTag) ) {

		let rootList = document.querySelectorAll(rootTag);
		rootList = Array.from(rootList);

		if (document.querySelector(option.openTag) ) {

			rootList.forEach((rootEl)=>{
				let openTag = option.openTag;
				let closeTag = option.closeTag || ".js-popup-close";
				let rootClass = option.rootClass || "is-popup-open";

				let openList = rootEl.querySelectorAll(openTag);
				openList = Array.from(openList);

				let closeList = rootEl.querySelectorAll(closeTag);
				closeList = Array.from(closeList);

				openList.forEach((openEl)=>{

					openEl.addEventListener("click", ()=>{
						rootEl.classList.add(rootClass);
					});
				});

				closeList.forEach((closeEl)=>{
					closeEl.addEventListener("click", ()=>{
						rootEl.classList.remove(rootClass);
					});
				});
			});
		}
	}
}